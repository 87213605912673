import {
    useState,
    useEffect
} from 'react'

import useDeepCompareCallback from '@gotamedia/fluffy/useDeepCompareCallback'
import { useSearchParams as remixUseSearchParams } from '@remix-run/react'

import type * as Types from './types'

const useSearchParamValues: Types.UseSearchParamValues = (params) => {
    const [searchParams] = remixUseSearchParams()

    const getSearchParams = useDeepCompareCallback(() => {
        const mapped = {} as ReturnType<Types.UseSearchParamValues>
        
        params.forEach((param) => {
            mapped[param] = searchParams.get(param)
        })

        return mapped
    }, [params, searchParams])

    const [mappedParams, setMappedParams] = useState<ReturnType<Types.UseSearchParamValues>>(getSearchParams())

    useEffect(() => {
        setMappedParams(getSearchParams())
    }, [getSearchParams])

    return mappedParams
}

export default useSearchParamValues